////
/// @group wk-css.components.document-legacy
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.1.0
/// @access public
////

@import 'wk-lib/utilities/exports';

/// Document bubble z-index
///
/// @type Unit
$wk-document-bubble-z-index: map-get($wk-z-index, document-bubble) !default;

/// Document bubble after z-index
///
/// @type Unit
$wk-document-bubble-after-z-index: map-get($wk-z-index, document-bubble-after) !default;

// scss-lint:disable all

@include wk-exports('document-legacy') {

    %scss-deps-document {
        utils: wk-utils-dependencies(
            function wk-rem-value,
            variable wk-font-weight,
            variable wk-headings-font-stack,
            variable wk-primary-colors,
            variable wk-z-index
        );
    }

    .wk-document-legacy .dps-resource {

        a {
            &.smallLink {
                &:link {
                    color: #0000cc;
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: 11px;
                    font-weight: normal;
                    text-decoration: underline;
                }
                &:active {
                    color: #000099;
                }
                &:visited {
                    color: #660066;
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: 11px;
                    font-weight: normal;
                    text-decoration: underline;
                }
                &:hover {
                    color: #0000cc;
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: 11px;
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
            &.small-link {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 11px;
                font-weight: normal;
                &:link {
                    color: blue;
                }
            }
            &.metadataLink {
                &:link {
                    color: #ffffff;
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: small;
                    font-weight: bold;
                    text-decoration: underline;
                }
                &:active {
                    color: #ffffff;
                }
                &:visited {
                    color: #ffffff;
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: small;
                    font-weight: bold;
                    text-decoration: underline;
                }
                &:hover {
                    color: #ffffff;
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: small;
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
            &.breadCrumb {
                &:link {
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: 11px;
                    color: #0000cc;
                    text-decoration: none;
                }
                &:active {
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: 11px;
                    color: #000099;
                }
                &:visited {
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: 11px;
                    color: #660066;
                }
                &:hover {
                    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                    font-size: 11px;
                    color: #0000cc;
                    text-decoration: underline;
                }
            }
            &:empty {
                display: none;
            }
        }
        .outer-table-wrapper {
            overflow: auto;
            margin-bottom: wk-rem-value(30);
        }
        .inner-table-wrapper {
            overflow: visible;
        }
        div {
            &.content-wrap {
                padding: 10px;
                padding: 0;
            }
            &.tableVspace {
                width: 100%;
                height: 16px;
                border: 1px  black solid;
            }
            &.path-line-content {
                margin: 12px 0 0;
                padding: 4px 0 0;
            }
            &.tochead {
                margin-left: 0;
                margin-top: 3pt;
                font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                font-size: 120%;
                font-weight: bold;
            }
            &.toc {
                margin-left: 10pt;
                margin-top: 3pt;
            }
            &.tocindent {
                margin-left: 10pt;
                margin-bottom: 6pt;
            }
            &.space {
                display: table;
                vertical-align: bottom;
                margin: 4px 0;
            }
            &.graphic {
                width: 100%;
                text-align: center;
            }
            &.warningMsg {
                color: #cc0000;
                font-weight: bold;
            }
            &.nav-caution-note-wrapper {
                position: relative;
            }
            &.cnBubble {
                left: 5px;
                z-index: $wk-document-bubble-z-index;
                position: absolute;
                min-width: 480px;
                padding: 0;
                background: #ffffbe;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                border: #ff8315 solid 2px;
                box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .75);
                -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .75);
                -moz-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .75);
                &:after {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-width: 8px 8px 8px 0;
                    border-color: transparent #ffffbe;
                    display: block;
                    width: 0;
                    z-index: $wk-document-bubble-after-z-index;
                    left: -8px;
                    top: 10px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-width: 9px 9px 9px 0;
                    border-color: transparent #ff8315;
                    display: block;
                    width: 0;
                    z-index: map-get($wk-z-index, base);
                    left: -11px;
                    top: 9px;
                }
            }
            &.cnContent {
                padding: 8px;
            }
            &.media-container {
                display: inline-block;
                margin: 5px 0 10px;
                span {
                    &.tooltip-hide {
                        display: none;
                    }
                    &.tooltip-show {
                        display: inline;
                        margin-left: 20px;
                        margin-top: 20px;
                        position: absolute;
                        border: 1px solid #cccccc;
                        background: #ffffff;
                        color: #6c6c6c;
                    }
                }
            }
            &.cnBody p {
                margin: 6px 0 0;
                font-style: italic;
            }
        }
        .docHeadSpacer {
            font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin-right: 4pt;
        }
        h1 {
            &.metadata {
                text-align: left;
                font-size: 120%;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
                margin-top: 12px;
                margin-bottom: 0;
            }
            &.valueadd {
                padding: 0;
                margin: 0;
                font: bold large/1em Arial, sans-serif;
                border-bottom: #660066 2px solid;
            }
        }
        h2 {
            &.valueadd {
                padding: 0;
                margin: 0;
                font: bold medium/1em Arial, sans-serif;
            }
        }
        h3 {
            &.valueadd {
                padding: 0;
                margin: 0;
                font: bold small/1em Arial, sans-serif;
            }
        }
        table {
            width: 100%;
            &.metadata {
                background-color: transparent;
                font-size: 8pt;
                font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                margin-top: 0;
            }
            &.noteIndent {
                margin-left: 15px;
            }
        }
        td {
            &.metadatahead {
                font-weight: bold;
                padding-left: 10pt;
                padding-right: 10pt;
                width: 150pt;
            }
            &.metadatahead_print {
                font-weight: bold;
                padding-left: 0;
                padding-right: 10pt;
                width: 150pt;
            }
            &.metadatahead2 {
                font-weight: bold;
                padding-right: 10pt;
            }
            &.olText {
                height: auto;
                padding-bottom: 10pt;
                padding-left: 6px;
            }
            &.digit {
                text-align: right;
            }
            &.small-cap {
                text-transform: uppercase;
                font-weight: normal;
            }
            &.bold-small-cap {
                text-transform: uppercase;
                font-weight: bold;
            }
            &.underline {
                text-decoration: underline;
                font-weight: normal;
            }
            &.roman {
                font-weight: normal;
            }
            &.bold {
                font-weight: bold;
            }
            &.italic {
                font-style: italic;
            }
            &.bold-italic {
                font-style: italic;
                font-weight: bold;
            }
            &.metricsCell {
                width: 25%;
                color: #888888;
                font-size: .85em;
                vertical-align: top;
                .dateCreated {
                    font-weight: bold;
                }
                .metrics .author {
                    &.deleted {
                        font-style: italic;
                    }
                    &.disabled {
                        font-style: italic;
                    }
                }
            }
            &.notesCell {
                width: 75%;
            }
            p.hp {
                margin: 0 0 10px 0;
            }
        }
        p {
            &.caption {
                font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                font-size: small;
                font-weight: normal;
                font-style: italic;
            }
            &.blockquote {
                font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                font-size: small;
                line-height: 1.25em;
            }
            &.related-topics-state {
                margin: 0 10px 0 0;
                padding: 0;
                font: bold italic small/1.3em Arial, Helvetica, sans-serif;
                color: #000080;
            }
            &.docType {
                display: block;
                padding: 0;
                font: bold small/1em Arial, sans-serif;
                color: #244680;
            }
            &:empty {
                display: none;
            }
        }
        pre {
            font-family: "Courier New", "Courier", "monospace";
            font-size: small;
        }
        span {
            &.toc {
                font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                font-size: 80%;
            }
            &.emphasis {
                font-weight: bold;
            }
            &.numemphasis {
                font-weight: bold;
                color: maroon;
                font-size: 110%;
            }
            &.footnote {
                font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
                font-size: small;
                line-height: 1.25em;
            }
            &.leftCol {
                display: inline;
                background-color: white;
                vertical-align: bottom;
                text-align: left;
            }
            &.rightCol {
                display: table-cell;
                float: right;
                background-color: white;
                text-align: right;
                vertical-align: bottom;
            }
            &.space {
                display: inline-block;
                vertical-align: bottom;
                text-align: left;
            }
        }
        .break {
            display: block;
        }
        ul {
            &.nested-ul {
                list-style-image: none;
            }
            &.square {
                list-style-type: square;
            }
            &.none {
                list-style-type: none;
            }
            &.bull {
                list-style-type: square;
            }
        }
        ul.bull > li {
            display: list-item !important;
        }
        li {
            margin: 0 0 10px 0;
        }
        .menupathBoxRow1 {
            width: 100%;
            height: 24px;
            text-align: right;
        }
        .menupathBoxRow2 {
            width: 100%;
        }
        .doc-path-wrapper {
            margin: 0;
            padding: 10px;
        }
        .doc_path_container {
            background-color: white;
            margin: 0;
            border: solid #00504c 1px;
            padding: 4px;
            div.navigation-line div.link-box {
                text-align: center;
                margin: 10px 0 5px;
            }
        }
        .clickpathNode {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
            font-weight: bold;
            color: #000000;
            text-decoration: none;
            margin: 0 5px;
        }
        .docid {
            width: 100%;
            height: 18px;
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            font-size: small;
            font-weight: bold;
            background-color: #00504c;
            color: #ffffff;
            padding: 2pt;
        }
        .date {
            color: navy;
        }
        .inline {
            color: purple;
        }
        .inline2 {
            color: green;
        }
        .center {
            text-align: center;
        }
        .metadataContainer {
            display: none;
        }
        .showMetadata {
            position: absolute;
            float: right;
        }
        .docSearchTerm {
            background-color: #ffff66;
            font-weight: bold;
        }
        #moreInfoBox {
            border: 1px solid #00504c;
            padding: 6px;
            width: 99%;
            -moz-box-sizing: padding-box;
        }
        .moreInfoText {
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-size: 11pt;
            color: #006699;
            text-transform: capitalize;
            white-space: nowrap;
        }
        .moreInfoBullet {
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-size: 14pt;
            color: #006699;
            text-transform: capitalize;
            white-space: nowrap;
            padding-left: 6px;
            padding-right: 6px;
            text-align: center;
        }
        .newDevBox {
            background: #ffffee;
            padding: 10px;
            bordnr: 1px solid map-get($wk-primary-colors, 'red');
            h1 {
                margin-top: 0;
                border-bottom: 2px solid #660066;
                font: bold small/1.5em Arial, sans-serif;
                text-transform: uppercase;
            }
            h2 {
                margin-bottom: 0;
            }
            p {
                margin-top: 0;
            }
        }
        #newDevBtn {
            width: 130px;
            height: 19px;
            padding: 2px;
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .newDevBtnHighlighted {
            border: 1px solid #0000cc;
            background-color: #f4f3c9;
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            color: #ff0000;
            font-weight: bold;
            font-size: 11px;
            text-align: center;
        }
        .quicklinks {
            margin: 4px 10px 0 15px;
            padding: 0;
            background: #fef8e3;
            border: 1px solid #ccc;
            font: small/1.3em Arial, Helvetica, sans-serif;
            p {
                margin: 0 12px;
                padding: 0 0 12px 0;
            }
            h1, h2, h3 {
                background-color: #003366;
                margin: 0 -1px 7px;
                padding: 4px 10px;
                color: #eee;
                font: bold small/1.3em Arial, Helvetica, sans-serif;
            }
            li {
                margin: 0;
                padding: 0 0 0 2px;
                line-height: 1.1em;
            }
            ul {
                margin-left: 12px;
            }
        }
        .deleted {
            font: bold small/1.3em Arial, Helvetica, sans-serif;
            color: #ff0000;
        }
        .vetoed {
            font: bold small/1.3em Arial, Helvetica, sans-serif;
            color: #0000ee;
        }
        .added {
            font: bold italic small/1.3em Arial, Helvetica, sans-serif;
        }
        .addedgreen {
            font: bold italic small/1.3em Arial, Helvetica, sans-serif;
            color: #008000;
        }
        #learningCenter {
            height: 178px;
            width: 596px;
            margin-top: 20px;
        }
        #LCloginBtn {
            position: relative;
            left: 470px;
            top: 140px;
        }
        .breadCrumb {
            padding: 8px 0;
        }
        #smartRelate {
            padding: 0 0 35px 0;
            margin: 0 3px 10px 6px;
        }
        .noteContainer {
            padding-left: 25px;
            margin: 15px 0;
        }
        .noteBody {
            font-family: $wk-document-font-stack;
        }
        .noteHead {
            font-weight: bold;
            margin: 0 0 0 15px;
        }
        .noteDisplayLabel,
        .note,
        .caution-note,
        .planning-note,
        .example,
        .faq,
        .comment,
        .cch-comment,
        .department-comment,
        .practitioner-comment,
        .checklist,
        .key-concepts,
        .tools,
        .gray-area,
        .cch-pointer,
        .cch-practice-tip,
        .compliance-tip,
        .compliance-pointer,
        .exam-standard,
        .expert,
        .forms,
        .legal,
        .policy,
        .sample-plan,
        .best-practice,
        .background,
        .summary,
        .iResearchAids,
        .cch-chart {
            color: #474747;
            font-family: $wk-headings-font-stack;
            font-weight: map-get($wk-font-weight, bold);
            font-size: 18px;
            margin-bottom: 6px;
            position: relative;

            &:before {
                background-repeat: no-repeat;
                background-position: 0 0;
                content: '';
                height: 16px;
                left: -25px;
                position: absolute;
                top: 3px;
                width: 16px;
            }
        }
        .caution-note, .caution-note + .noteBody {
            color: map-get($wk-primary-colors, 'red');
        }
        .caution-note:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQcALvi///sqP/nnd7w///tsd7x/9fu/+n1/+n2/9Ls///tt8Dk/8Dj/+Pz/9Hr/7vh///qov/daprN87u7u//jk2OQsXSs1uTz/5kAAMwAAP///5lmM////wAAAAAAAAAAACH5BAEAABwALAAAAAAQABAAAAV4IMdZZGmS4nit67axa6VqtLZp1MtKlXXVNpxuxfPVbhlMbtf70ZCYyAZxOBRZN0UyAnk1roXCDbLtDgZXwzhQ7hquiRshABEIKDnHVU7f4nN7OHgRhH8bVwsuiosbDFcPAJEAD5SQlBMyFhObnJ2bMiIVoqOkoiIhADs=);
        }
        .noteDisplayLabel,
        .note,
        .compliance-tip {
            &:before {
                background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQbALvi/8Dj/7vh/9fu/8Dk/8bm/9ju/9Ls/+nv4d7w/97x///ff9Hr///44en1/6HO6ufw7Pe+W20/Ef/ShJrN82OQsXSs1uTz//+ZM5lmM////////wAAAAAAAAAAAAAAACH5BAEAABsALAAAAAAQABAAAAV74LZZZGmS4nitbLtWqiZrWZ21VGVdzZxpkdsKkdvJfr/gRVi80DCSZE0jcTQlmgl0EsFqJU2Fd0ImQxPNwWBslhgGRcjhVy5DGQ/dUrOAAr1QTQUSUFJYEgVNBAQSAVIBjQFNAgCVNhmUAgI5IxSen6CeMCIVpaanpSIhADs=);
            }
        }
        .planning-note,
        .compliance-pointer,
        .summary {
            &:before {
                background-image: url(data:image/gif;base64,R0lGODlhEAAQALMNAL1IAvvsx//jk//wxP/dam0/EcVrI5rN82OQsXSs1uTz/////5lmM////wAAAAAAACH5BAEAAA0ALAAAAAAQABAAAARfsLVEq6Vyqs37RhojjuR2IInCLGy7rIxyputg3zUzqwswGEBDYVjYrQK35KCI4gEEwaCASVsIrlgs1QmNGqbGBWFMJm9XAILXQDiLy+UzcT4/TQ74vB4PkiD+gIF/EhEAOw==);
            }
        }
        .comment,
        .cch-comment,
        .department-comment,
        .practitioner-comment {
            &:before {
                background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQTAMDj/7vh/7vi/8Dk/8Xm/8bm/8vo/8vp///vvG0/EaN0QZrN8/e+W+Tz/2OQsXSs1v/ShP+ZM////////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABMALAAAAAAQABAAAAVz4DQ9ZGmS4tisbLs6qiQrdM0uztNItOzzigZOp5AhjshjYliEOJ9QSCLXjEanj6r1iS0yvuAwA9tQiMXLXMNAi7jfkcRh2CgQCIm8O08o0AcDAACBCXGBAHQBAosCAQF5jQE4IwuVlpVLlTAiDp2en50iIQA7);
            }
        }
        .sample-plan,
        .background {
            &:before {
                background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQQALvh/8Dj/8Xm/7vi/8bm/8GAP5rN8+Tz/3Ss1v/dav/jk2OQsf/wxG0/Ef///5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABAALAAAAAAQABAAAAVwIAQhJPKcZ0GK4+EeDyMzRXMsreOgKV0bC8RBF5vVZA2gkPco+BqMZBA2o0GjCumyWqhls0pqFdsATx+KtLrcSGhh6rXbHUbHs+7G+5Ho+9sNAgR1fn96AQGEhW4AAwAAQCN6k5MGlpEiC5qbnJoiIQA7);
            }
        }
        .example:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQfAF99AMDj/9fu/9HfnPe+W+n2/8vp/97w/+n1/93w/9Hr/8Dk/8Xm/97x/9ju/+r2/8bm/9Hs/+Pz/9jt/7vi/7vh/5rN82OQsW0/EXSs1uTz//+ZMwAAAJlmM////////yH5BAEAAB8ALAAAAAAQABAAAAWD4PdlZGmS4qitK9dy7qV6tKfB7q1ZV6bVHhhLx/PRhENi78ehaR6IghSBKLZYklVnu8X4GpzEoXE4JDpAr0YgcLAdEwF6MAB4uV1FRDGv39MGgWgAhH8EBBsYEAwMGI6PGWiHiQEBC5aVRWgbnBgVnxUUnzwfj48WqKmkIhetrq+tIiEAOw==);
        }
        .faq:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQANUgAMDj/7vi/9Hr/8Xm/7vh/8bm/97w/8Dk//e+W8vo/97x/9Lr/9ju/8DY8d3w/8zo/+Pz/9Hs/+r2/+n1/9Ls/9fu/8vp///ShG0/EZrN82OQsXSs1uTz//+ZM////5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAACAALAAAAAAQABAAAAaNQBBoQywaicIhZ8n5OD9MjkbpqX6qVQwzo9lwrJ6L+ILQcrhez1WMwZA7WvT308ZIPm/MRB7lYMJwHBByCgoOBmtwDQYGchUVDBV4cI+RchQCmXRtCwsRAnIJFqMPoxaio3IFAwMFTxirrXIHBwAAV3AAtQByBAG/mxi+BARcQxnIycrIU0Iaz9DRz0JBADs=);
        }
        .checklist:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQbAMXm/7vi/8vp/8Dk/8bm/9fu/97w/9Lr/8vo/+r2/+n1/9ju/9Hr/9Hs/+Pz/8Dj/7vh//e+W//ShG0/EZrN82OQsXSs1uTz//+ZM5lmM////////wAAAAAAAAAAAAAAACH5BAEAABsALAAAAAAQABAAAAWA4LZZZGmS4nitbLtWqiZrbpZdVGVdM81mmglOx5v9NBJhbte7JICSyESxXNmsyAhm4qhmJBpbdmswLCeYCHg8KSwKVbRkrp0cDg1GFYGO1AUIAoJVBABoWwAAhYpVAwMPExMPD4+PD1UQAZoBEJ2ZnTkjFKOkpaMwIhWqq6yqIiEAOw==);
        }
        .key-concepts:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdAGs8DLvh/8vp/8Dj/8Xm/8bm/97w/9fu/+Pz/5CQkNju/9Lr/9Hr/7vi/+r2/97x/8Dk/9Hs/8vo//e+W5rN82OQsXSs1m0/Ef/ShOTz//+ZM5lmM////////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWDYNdZZGla2yZaWeu+G4ddLGdzbxZjE53dONeu5wMKZT1KpXbLOGITjeaiZKWuu+kFUY0BkZeHwaDcYc7n6OWgOJRlaJ52EWG8MamL/CIR+O8bBHpqBQQEBV0zF4NTAxADA1U6E5SUUwENAQFKHQkUG1JSABSkpBUiHRUVAKwAqq+nHSEAOw==);
        }
        .tools:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQcAMDj/8vp///ShMXm/9Hr/9fu/8bm/8Dk/7vi/9Lr/97x/8vo/9Ls/97w/9ju/93w/+n2/9Hs/7vh//e+W20/EZrN83Ss1mOQseTz//+ZM5lmM////////wAAAAAAAAAAACH5BAEAABwALAAAAAAQABAAAAV+IMdZZGmS4oitLKa966Vu9LZqW6at1WVhNRtOx+r9gq7N7kbxAWtJgQaiETR/rah0krliFIpHA8fNmClGTKHgIHcp8EKaQXBTCIlEhJBe2AMBC4ABaXYGAwOHiUZ2BwAAjo4APTgUXQgIEpoSmRKUXGgVoqOjMhxwF6mqq6YhADs=);
        }
        .cch-pointer:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQYALvi/7yUacvp/8Dj/8Xm/97w/8bm/8Dk/9Lr/9Hr/9Hs/9ju/9fu//e+W7vh/5rN8+Tz/2OQsXSs1m0/Ef/ShP+ZM////5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABgALAAAAAAQABAAAAV1IIZJZGmS4gitbLtGqiVbwWW3TyRBcn3JF5wO4pNRbEibzmakOJ/OiW5igUYnWOzUCp0UCjnJhEvJLhjhSaPBnSAUiXSlsq5jBfg0ds6fGAgEBmEQBwFZWQMHAwODDgCPAA6SjpI5Iw+YmZqYMCIRn6ChnyIhADs=);
        }
        .cch-practice-tip:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAOZnALvh///omf/lj//jhf/trsbm/5J3Wsvp/2c1A//hfZS92eHu+cDj/3BDFZmWkHNHG6WASKyHS/jdiNHs/8vo/6GCY/vfgsrR1ZFoMal/Nn5bNphtLJuWjaq+zMjc6t/CeXROJ9LY229BEoVWF4FqUeDn7Y2Ab8Crls6yd8Dk/4ZaJbKMTKmmn8DGydLl87CNVIFrUd/UyXZJG6qtq8Xm/52kpbvi/8Slapyiou/ZnJbA34JpTqaUgZBxUayMYKqBOJxwKdfu/+jGav/wvcS+t+Px/Kq4wOTb0srn/PrmqNCsV9Ls/7ugfsCib2s8DHhHD97q88WlZmg3BryVQoxeHf3z0PLgraydjbrR4dHr/6B2NqSSfmo6CYVkQqqYhGs6CXVSLf/qo5rN82OQsXSs1uTz/////////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAGcALAAAAAAQABAAAAe6gGdnZISFhoSCg2WLizwLjItjimaUZiEIV5BlYmNkZZVmBkwiUJCcnpVHCFU+W6adRTEnFTJNQ1ZfPV5EJacGKhA3OQTESSgvGA8KnQtdEWHQ0WFRDRenZS4aKwHc3B9cLUHXSx5OEgLoAlosE1nXFEhSFgP0AxszBwfXBVhPCUIZlCT4wYFGgWspjFABAsLBjhFTTDBgcA0ADjA1bAAA0IEEjI2cBolRIKakSTE6SkoSNKaly5ctBQUCADs=);
        }
        .exam-standard:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQALMPALvi/45kOry8vLvh///wxP/jk5rN8//danSs1uTz/2OQsW0/Ef+ZM5lmM////////yH5BAEAAA8ALAAAAAAQABAAAARv8D1Eq6Vyps37VpojOh5nKEjSrKvKLsnJjs5CL82iNA5P/LYfwabj8QpIG7KwGO56jqUyyXzyDlgb9rCoGh1bbZb7XJgXDOKZfD0wAucAY55WnO+C+xmVGAD+AAOCfoInEwaIiYqIIBIKj5CRjxIRADs=);
        }
        .expert:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQZALvi/7vh/6wAAN3v9t7v9uPz/8LT1+n1/8Dj/97x/+H1+eTz/9IAAPe+W//wxP/dansAAP/ShJrN82OQsXSs1m0/Ef+ZM5lmM////////wAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABkALAAAAAAQABAAAAV8YJZRZGmS4nitbHsZ01hZFWbfdyVN1ExXwGBw12sYj8fKYkFcVCLQaLRyIA6eUmml0ExUHOBwWMcjKGo4DASCIVNYlYf8wVa7Lw/anG6D3B8+FoIWazRNQkEQDAwQCE0BAJEAAQECjAIBOyMSnJ0SjIqaIhOkpRMMpKgiIQA7);
        }
        .forms:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQALMLAP/dam0/EZrN82OQsf/wxHSs1sVrI/+ZMwAAAJlmM////////wAAAAAAAAAAAAAAACH5BAEAAAsALAAAAAAQABAAAARgcK1Cq6Vypc37DsOUKGRpKoEwFKPhvi+RriNh3/jMKnAvqzsD7mYA6Fo9A6J4VACeUAACYQQiewgFoikcZrVWXvKrpSkO6PSBrGUF3vD3d4qYCO74exahygz+gH9TfxIRADs=);
        }
        .legal:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdANfu/8bm//e+W8Xm/9Hr/8Dk/7vi/97x/9Hs/8vo/7vh/97w/93w/9Ls/9ju/9Lr/+Pz/8Dj/8vp///dav/ShJrN82OQsXSs1m0/EeTz//+ZM////5lmM////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWFYNddZGmS4pitbMa1lrrN28ptGFtZV0bXHAoGt9r1fhwNhkIhGn20DEYjYOKerQwkWR1ieJnDgbE4cCbTJeYJADgAZwwgDXg2CIQ4gvDA7J8JEnEShIGETwFxAQEDA4yOT3ERBRGTlhE7HHEGnAqan5oWcRWkFVxNFAIaHBitFq8WrbKzIQA7);
        }
        .policy:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQeAObm5svp/4yMjN7w/9fu/8Xm/7vh/8Dj/4CAgP+ZmaZ+Tsbm/9Hs/7vi/+Pz/9Hr/8Dk/9Lr/9ju//9mZv/wnnSs1prN820/EWOQsaampv+ZAJlmMwAAAP///////wAAACH5BAEAAB4ALAAAAAAQABAAAAWAoOdVZGlyqLetbHtxQJJwW2ffN9cB2UR3maAwo+NlBD+cjUNBBJGY2pBI6WgQSEtUybRqNByHVhrsfsGDwThXPXMIEsI6SDmDLxHGY22j1MEdFwGDcxkUCgocGYEFBQt8OkoXEAcHfEo2Fw0GBloXn6ChFxakGCIeGKmqqx2mHiEAOw==);
        }
        .best-practice:before {
            background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdAMvp/9fu/8Xm/8Dj/7vh/7vi/8Dk/8bm/9Hr/97w/9Lr/8vo/97x/+Pz/9ju/+n1/9Hs/9Ls//e+W//wxJrN8+Tz/3Ss1v/damOQsW0/Ef+ZM////5lmM////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWAYNdZJGeaJCmOVcVN8KRlbYWxG3dt23VJsxYFY3FNeDwfkDYsvpC9X7Bp3EWlmQf15etiG1QGt7tMJKiBwHgZcASoEQTitVQoIAjqAgCgz/Z8VAcCAhmGhoMCB1QGBgN0EhkDjgNUBAUFh4aXBARDIxShkBmhoTciGKkYh6qpIiEAOw==);
        }
        .BNanoteContainer {
            clear: right;
            margin: 0 45px 15px;
            border-style: solid;
            border-color: #9fb1bc;
            border-width: 1px;
            padding: 3px;
            float: right;
            width: 25%;
        }
        .BNanote {
            background-color: #4e82a3;
            color: white;
            font-size: medium;
            font-weight: bold;
            margin: 0;
            padding-left: 15px;
        }
        .BNanoteBody {
            margin-top: 0;
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            font-size: small;
            margin: 0 5px 15px;
            color: #000000;
        }
        #footer {
            position: relative;
            padding: 0;
            margin: 0;
            height: 32px;
            _height: 31px;
            width: 100%;
            border-top: 1px solid #3366cc;
        }
        .footer {
            position: relative;
            padding: 0;
            margin: 0;
            height: 32px;
            _height: 31px;
            width: 100%;
            border-top: 1px solid #3366cc;
            font-size: 11px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000000;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 13px;
            color: #757575;
            border-color: #d9dde7;
            padding: 20px 0 17px;
            margin-top: 20px;
        }
        .header {
            position: relative;
            padding: 0;
            margin: 0;
            width: 100%;
            border-bottom: 1px solid #3366cc;
            font-size: 11px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000000;
        }
        .blueRule {
            width: 100%;
            margin: 10px;
            border-top: 1px solid #3366cc;
        }
        #document-separator {
            width: 100%;
            border-bottom: 5px solid #33dddd;
        }
        .smallText {
            padding: 0;
            margin: 0;
            font-size: 11px;
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            color: #000000;
        }
        .hideText {
            display: none;
        }
        .fracNum {
            vertical-align: .2em;
        }
        .fracDen {
            vertical-align: -.2em;
        }
        #menupathIFrame {
            width: 100%;
            height: 0;
            border: 0 none;
            margin: 0;
        }
        .headnote {
            font: small Arial, Helvetica, sans-serif;
        }
        .history {
            font: small Arial, Helvetica, sans-serif;
        }
        .source-info {
            font: small Arial, Helvetica, sans-serif;
        }
        .reference-line {
            font: small Arial, Helvetica, sans-serif;
        }
        .balloonHelpIcon {
            height: 10px;
            width: 10px;
            cursor: help;
            margin-left: 2px;
            margin-right: 2px;
        }
        .not-indented-level {
            margin-left: 0;
        }
        .indented-level {
            margin-left: 20pt;
            margin-left: 0;
            > .indented-level {
                margin-left: 20px;
            }
        }
        .menupath-link {
            display: inline;
            padding-left: 5px;
        }
        .historyVaNum {
            padding-right: 6px;
            white-space: nowrap;
            vertical-align: top;
        }
        .relateBlock {
            display: none;
            display: none;
        }
        .docNotInFocus {
            background-color: #eeeeee;
            a.metadataLink {
                &:link {
                    color: #eeeeee;
                }
                &:active {
                    color: #eeeeee;
                }
                &:visited {
                    color: #eeeeee;
                }
                &:hover {
                    color: #eeeeee;
                }
            }
        }
        .accmenu {
            font-size: .9em;
            border: 1px solid #a0a0a0;
            width: 180px;
            padding: 5px;
            position: absolute;
            background-color: #f0f0f0;
            border-radius: 3px;
            box-shadow: 0 0 5px #aaaaaa;
            .option {
                padding: 2px 0;
                cursor: pointer;
                &:hover {
                    background-color: #e0f1c6;
                }
                &.disabled {
                    color: gray;
                    cursor: default;
                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
                .labelwrapper {
                    display: inline-block;
                    zoom: 1;
                    *display: inline;
                    vertical-align: top;
                }
                .img {
                    cursor: pointer;
                    display: inline-block;
                    zoom: 1;
                    *display: inline;
                    padding-right: 3px;
                }
                &.printfragment {
                    .labelwrapper {
                        margin-left: 2px;
                    }
                }
            }
        }
        .documentLevelNoteWrap {
            padding-bottom: 10px;
        }
        .sideNoteasFooter {
            border-top: 1px dashed black;
            table {
                width: 100%;
            }
        }
        .topanswer {
            background-color: #e9f2f7;
            border: 1px solid #abcce2;
            padding: 6px;
            margin: 10px;
            h2 {
                color: #006699;
                margin: 0 0 10px;
            }
        }
        .docTitle {
            font-size: 22px;
            font-weight: 400;
            color: #323232;
            margin: 0 0 20px;
        }

        .docHeadSpacer {
          font-size: 16px;
          font-weight: 400;
        }
        .hP,
        .hP[style] {
            display: block !important;
            font-size: 17px !important;
        }
        .hP {
            color: #323232;
        }
        .docTitle,
        .leadIn,
        .level-heading {
            font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;

            a:hover {
                text-decoration: none;
            }
        }
        .leadIn,
        .level-heading {

            .smallCap, .smallCap[style] {
                font-size: 19px !important;
                font-weight: 700;
                line-height: normal;
            }

            i {
                font-style: normal;
            }
        }
        .leadIn,
        .level-heading {
            font-size: 19px;
            font-weight: 700;
        }
        .leadIn {
            line-height: normal;
            margin-bottom: 4px;

            > b {
                font-weight: normal;
            }
        }
        .level-heading {
            margin: 0;
        }
        .smallLink a {
            font-size: 11px;
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            color: #0000cc;
            text-decoration: none;
            &:hover {
                color: #0000ff;
                text-decoration: underline;
            }
        }
        thead.xhtml {
            font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
            font-size: 11px;
            font-weight: bold;
            font-style: normal;
        }
        hr.rule {
            color: black;
            background-color: black;
            border-width: thin;
            height: 1pt;
        }
        .hlOn .docSearchTerm span {
            &.hl-none {
                background-color: #ffff66;
                font-weight: bold;
            }
            &.hl-yellow {
                background-color: #ffff66;
                font-weight: bold;
            }
            &.hl-pink {
                background-color: #ffff66;
                font-weight: bold;
            }
            &.hl-orange {
                background-color: #ffff66;
                font-weight: bold;
            }
            &.hl-green {
                background-color: #ffff66;
                font-weight: bold;
            }
            &.hl-blue {
                background-color: #ffff66;
                font-weight: bold;
            }
        }
        .dateUpdated .modifiedLabel {
            font-weight: bold;
        }
        .nav-caution-note-wrapper[style] {
            margin-left: 0 !important;

            tr[style] {
                padding-bottom: 0 !important;
            }

            .nav-caution-note {
                display: none;
            }

            .cautionImgR {
                display: none;
            }
        }
        .nav-caution-note {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAIAAAALR8HoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowQUZDM0E4NzgwRTAxMUU1QTk0Q0ZGRTI2OEM5NDEzNyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowQUZDM0E4ODgwRTAxMUU1QTk0Q0ZGRTI2OEM5NDEzNyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBBRkMzQTg1ODBFMDExRTVBOTRDRkZFMjY4Qzk0MTM3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjBBRkMzQTg2ODBFMDExRTVBOTRDRkZFMjY4Qzk0MTM3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+SQxZMQAAAF1JREFUeNqU0ssJADEIBNB8StI2LN2ajOyKgUVWnZOHeUQwU0TGE2YeUQDA57ezusBMC2g2EbXA3a0OYvMPApOCr6kA7ZjRK6XAO2YQMX3BO6u+ks/T/079uEeAAQCPfDz5xZEe4wAAAABJRU5ErkJggg==);
            background-repeat: repeat;
            height: 100%;
            margin-left: -37px;
            position: absolute;
            width: 17px;
        }
        .cautionText {
            background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAARElEQVQYlYXOMQ4AIAhD0crG/Q/bEReMARH+/NJ0mRlOJFElo1BVGYUv9QKAjCJ8+gmSBUqiWHpFRqUI6CcuaoSjXgDYStwm+pRLfNoAAAAASUVORK5CYII=) 0 0 repeat;
            border: 1px solid #a8a8a8;
            color: #000;
            font-family: $wk-headings-font-stack;
            font-size: 17px;
            font-weight: map-get($wk-font-weight, light);
            line-height: 24px;
            margin-bottom: 25px;
            padding: 10px 10px;
            text-align: center;

            &:before {
                background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2014%22%3E%3Cpath%20fill%3D%22%23EC373B%22%20d%3D%22M8.044%200.151C7.877%200.05%207.694%200%207.501%200%207.305%200%207.125%200.05%206.958%200.151%206.79%200.252%206.659%200.389%206.564%200.562L0.143%2012.41c-0.195%200.354-0.19%200.707%200.016%201.061%200.095%200.163%200.223%200.291%200.388%200.387C0.712%2013.953%200.889%2014%201.079%2014h12.843c0.188%200%200.367-0.047%200.531-0.143%200.163-0.096%200.294-0.224%200.388-0.387%200.206-0.354%200.212-0.707%200.018-1.061L8.438%200.562C8.342%200.389%208.211%200.252%208.044%200.151%22%2F%3E%3Cg%20id%3D%22XMLID_1_%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M8.57%209.97v1.601c0%200.08-0.03%200.14-0.08%200.199%20-0.05%200.051-0.11%200.08-0.19%200.08H6.7c-0.08%200-0.14-0.029-0.19-0.08%20-0.05-0.06-0.08-0.119-0.08-0.199V9.97c0-0.08%200.03-0.14%200.08-0.2C6.56%209.72%206.62%209.69%206.7%209.69h1.6c0.08%200%200.141%200.029%200.19%200.079C8.54%209.83%208.57%209.89%208.57%209.97zM8.71%204.56L8.55%208.42c0%200.061-0.03%200.1-0.09%200.14C8.41%208.6%208.35%208.62%208.27%208.62H6.72c-0.08%200-0.14-0.021-0.2-0.061C6.47%208.52%206.44%208.48%206.44%208.42L6.3%204.58c0-0.08%200.02-0.14%200.08-0.18%200.07-0.06%200.14-0.09%200.2-0.09h1.84c0.061%200%200.13%200.03%200.2%200.09C8.68%204.44%208.71%204.49%208.71%204.56z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
                background-position: 0 0;
                background-repeat: no-repeat;
                content: '';
                display: inline-block;
                height: 15px;
                margin-right: wk-rem-value(5);
                position: relative;
                top: 1px;
                width: 15px;
            }
        }

        .endnotes {
            border-top: 1px solid #dee3ea;
            margin-top: 35px;

            .fnBoxL {
                display: none;
            }

            .endBoxTop > {
                h1, h2, h3, h4, h5, h6 {
                    font-family: $wk-headings-font-stack;
                    font-size: 20px;
                    font-weight: map-get($wk-font-weight, semibold);
                    margin-bottom: 15px;
                }
            }

            .endBoxBottom > table > tbody > tr[style] {
                padding-bottom: 0 !important;
            }

            .tableNote sup {
                top: auto;
            }

            .footnoteRef,
            .footnote {
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }

            .footnoteRef {
                font-size: 15px;
                font-weight: map-get($wk-font-weight, bold);
                position: relative;
                top: 1px;
            }

            .footnote {
                font-size: 15px !important;
                line-height: 18px;
                margin-bottom: 11px;
                margin-left: 15px;
            }
        }
    }
}
